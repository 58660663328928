
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';

@Component({})
export default class SkillSwitch extends Vue {
  innerValue = false;
  @Prop({ default: false }) value!: boolean;

  mounted():void{
    this.innerValue = this.value;
  }
  @Watch('innerValue')
  Watch():void {
    this.$emit('input', this.innerValue);
  }
}
