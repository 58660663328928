
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';

@Component({
})
export default class SelectFamilyType extends Vue {
  innerValue = '';
  @Prop({ default: '祖父' }) value!: string;

  mounted(): void {
    this.innerValue = this.value;
  }

  @Watch('innerValue')
  watch():void {
    this.$emit('input', this.innerValue);
  }
}
