
import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';
import AddressApi from '@/module/api/AddressApi';
import SelectFamilyType from '@/components/User/SelectFamilyType.vue';
import FamilyMember from '@/components/User/FamilyMember.vue';
import SkillChek from '@/components/User/SkillCheck.vue';
import Help from '@/components/Parts/Help.vue';
import FileUpload from '@/components/Parts/FileUpload.vue';
import EngineerInfoClass from '@/model/user/engineer/EngineerInfoClass';
import FamilyInfo from '@/model/user/family/FamilyInfo';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import PcskillNumInfo from '@/model/user/pcskill/PcskillNumInfo';
import PcskillInfo from '@/model/user/pcskill/PcskillInfo';
import UserID from '@/model/user/UserID';
import UploadFileContainer from '@/model/type/UploadFileContainer';
import UserBaseInfo from '@/model/user/UserBaseInfo';

@Component({
  components: {
    SelectFamilyType,
    FamilyMember,
    SkillChek,
    Help,
    FileUpload,
    InputJpDate
  }
})
export default class EngineerInfo extends Vue {
  addcnt=0;
  familyindex =0;
  familyMembers = [];
  show = false;
  showDialog = false;
  pcSkillNuminfo!:PcskillNumInfo;
  pcSkillNuminfoList!:PcskillNumInfo[];
  dialogData:PcskillInfo = new PcskillInfo();
  uploadfile:UploadFileContainer = new UploadFileContainer();
  @Prop() loginUser!:UserBaseInfo;
  @Prop() engineer!:EngineerInfoClass;
  @Prop() family!:FamilyInfo[];
  @Prop() appendedfamily!:FamilyInfo[];
  @Prop() deletefamily!:FamilyInfo[];
  @Prop() userId!:UserID;
  @Prop() loadCompleted!:boolean;

  familyAdd():void{
    this.appendedfamily.push(new FamilyInfo());
    // eslint-disable-next-line no-plusplus
    this.addcnt++;
  }

  familydel(familyList:FamilyInfo[], idx: number):void {
    this.deletefamily.push(familyList[idx]);
    familyList.splice(idx, 1);
  }
  apendedfamilydel(familyList:FamilyInfo[], idx: number):void {
    familyList.splice(idx, 1);
  }
  openPcskillDialog(pcskillInfo:PcskillInfo):void{
    this.dialogData = pcskillInfo;
    this.showDialog = true;
  }

  closeDialog(closeDialog:boolean):void{
    this.showDialog = closeDialog;
  }

  SearchAddress(zip:string):void{
    AddressApi.Search(zip).then((res) => {
      this.engineer.addresshotel.address = res.data;
    });
  }
  /**
   * PCスキルの表示を更新
   */
  updateSkill(updated:PcskillInfo): void{
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.engineer.pcskillInfoList.value.length; i++) {
      if (this.engineer.pcskillInfoList.value[i].pcskillId.value === updated.pcskillId.value) {
        this.engineer.pcskillInfoList.value[i].userPcskillInfoList = updated.userPcskillInfoList;
      }
    }
    this.changeItem('pcskill');
    this.closeDialog(false);
  }
  changeItem(item:string):void{
    if (this.loadCompleted) {
      this.$emit('changeItem', item);
    }
  }
  @Watch('engineer.addresshotel.address.value')
  changeAddress():void {
    this.changeItem('addresshotel');
  }
  fileUpload(file:File):void{
    this.$emit('fileUpload', file);
  }
}
