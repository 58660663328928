import SkillNum from './SkillNum';

export default class UserPcskillInfo {
  skillNum: SkillNum;

  constructor(
    skillNum?: SkillNum
  ) {
    this.skillNum = (skillNum === undefined ? new SkillNum() : skillNum);
  }

  public static cloneList(source:UserPcskillInfo[]):UserPcskillInfo[] {
    const result : UserPcskillInfo[] = [];
    source.forEach((s) => {
      result.push(UserPcskillInfo.clone(s));
    });
    return result;
  }
  public static clone(source: UserPcskillInfo):UserPcskillInfo {
    const result = new UserPcskillInfo();
    result.skillNum = source.skillNum;
    return result;
  }
  public static create() {
    const result: UserPcskillInfo[] = [];
    return result;
  }
}
