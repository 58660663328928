
import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectFamilyType from '@/components/User/SelectFamilyType.vue';
import FamilyInfo from '@/model/user/family/FamilyInfo';
import InputJpDate from '@/components/Parts/InputJpDate.vue';

@Component({
  components: {
    SelectFamilyType,
    InputJpDate
  }
})
export default class FamilyMember extends Vue {
  @Prop() family!:FamilyInfo;
  @Prop() mode !:string;

  delfamily():void{
    this.$emit('familydel', this.family);
  }
  appendedfamily(appendedfamily:FamilyInfo):void{
    this.$emit('apendedfamilydel', appendedfamily);
  }
  changeItem():void{
    this.$emit('changeItem');
  }
}
