import TypeJpDate from '@/model/type/TypeJpDate';
import UserID from '../UserID';
import FamilyType from './FamilyType';
import ID from './ID';
import Name from './Name';
import NameKana from './NameKana';
import Work from './Work';

export default class FamilyInfo {
  id: ID;

  userId:UserID;

  name:Name;

  nameKana:NameKana;

  familyType:FamilyType;

  work:Work;

  birthday:TypeJpDate;

  constructor(
    id?:ID,
    userId?:UserID,
    name?:Name,
    nameKana?:NameKana,
    familyType?:FamilyType,
    work?:Work,
    birthday?:TypeJpDate
  ) {
    this.id = (id === undefined ? new ID() : id);
    this.userId = (userId === undefined ? new UserID() : userId);
    this.name = (name === undefined ? new Name() : name);
    this.nameKana = (nameKana === undefined ? new NameKana() : nameKana);
    this.familyType = (familyType === undefined ? new FamilyType() : familyType);
    this.work = (work === undefined ? new Work() : work);
    this.birthday = (birthday === undefined ? new TypeJpDate() : birthday);
  }

  public static cloneList(source:FamilyInfo[]):FamilyInfo[] {
    const result :FamilyInfo[] = [];
    source.forEach((s) => {
      result.push(FamilyInfo.clone(s));
    });
    return result;
  }

  public static clone(sourse: FamilyInfo):FamilyInfo {
    const result = new FamilyInfo();
    result.id = sourse.id;
    result.userId = sourse.userId;
    result.name = sourse.name;
    result.nameKana = sourse.nameKana;
    result.familyType = sourse.familyType;
    result.work = sourse.work;
    result.birthday = sourse.birthday;
    return result;
  }
  public static create() {
    const result: FamilyInfo[] = [];
    return result;
  }
}
