import { AxiosPromise } from 'axios';
import PcskillInfo from '@/model/user/pcskill/PcskillInfo';
import axios from '../AxiosBase';

export default {
  /**
   * スキル項目取得
   */
  findskillNumAll(skillId:string):AxiosPromise<any> {
    return axios.get(`pcskill/findnum/${skillId}`);
  },

  update(skillinfo:PcskillInfo):AxiosPromise<any> {
    return axios.post('pcskill/update', skillinfo);
  }

};
