
import { Component, Vue, Prop } from 'vue-property-decorator';
import SkillSwitch from '@/components/User/SkillSwitch.vue';
import PcskillApi from '@/module/api/PcSkillApi';
import PcskillNumInfo from '@/model/user/pcskill/PcskillNumInfo';
import PcskillInfo from '@/model/user/pcskill/PcskillInfo';
import UserPcskillInfoList from '@/model/user/pcskill/UsrePcskillInfoList';
import UserPcskillInfo from '@/model/user/pcskill/UserPcskillInfo';

@Component({
  components: {
    SkillSwitch
  }
})
export default class SkillChek extends Vue {
  showDialog = false;
  @Prop() pcskillInfo!:PcskillInfo;
  pcSkillNuminfoList:PcskillNumInfo[]=[];

  mounted():void{
    PcskillApi.findskillNumAll(this.pcskillInfo.pcskillId.value).then((res) => {
      this.pcSkillNuminfoList = res.data;
      // 各スキル項目ごとにできる・できないを判定
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.pcSkillNuminfoList.length; i++) {
        const num = this.pcSkillNuminfoList[i].skillNum.value;

        // ユーザーが保持している項目はチェックする
        const bln = this.pcskillInfo.userPcskillInfoList.value.some((s) => s.skillNum.value === num);
        this.pcSkillNuminfoList[i].userPcskillcheck = bln;
      }
    });
  }
  pcskillSave():void{
    const savePcskill:UserPcskillInfoList = new UserPcskillInfoList();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.pcSkillNuminfoList.length; i++) {
      if (this.pcSkillNuminfoList[i].userPcskillcheck) {
        savePcskill.value.push(new UserPcskillInfo(this.pcSkillNuminfoList[i].skillNum));
      }
    }
    const saved = PcskillInfo.copy(this.pcskillInfo, savePcskill);

    PcskillApi.update(saved).then(() => {
      this.$emit('updateSkill', saved);
    });
  }

  closeDialog():void{
    this.$emit('closeDialog', false);
  }
}
