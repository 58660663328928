export default class Name {
  value: string;

  constructor(value?:string) {
    this.value = (value === undefined ? '' : value);
  }
  public static clone(source: Name): Name {
    return new Name(source.value);
  }
}
