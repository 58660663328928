import UserID from '../UserID';
import PcskillID from './PcskillID';
import PcskillName from './PcskillName';
import SkillStarInfo from './SkillStarInfo';
import SkillStarInfoList from './SkillStarInfoList';
import UserPcskillInfoList from './UsrePcskillInfoList';

export default class PcskillInfo {
  userId:UserID;

  pcskillId:PcskillID;

  pcskillName:PcskillName;

  userPcskillInfoList:UserPcskillInfoList;

  skillStarInfoList:SkillStarInfoList;

  constructor(
    userId?:UserID,

    pcskillId?:PcskillID,

    pcskillName?:PcskillName,

    userPcskillInfoList?:UserPcskillInfoList,

    skillStarInfoList?:SkillStarInfoList,

  ) {
    this.userId = (userId === undefined ? new UserID() : userId);
    this.pcskillId = (pcskillId === undefined ? new PcskillID() : pcskillId);
    this.pcskillName = (pcskillName === undefined ? new PcskillName() : pcskillName);
    this.userPcskillInfoList = (userPcskillInfoList === undefined ? new UserPcskillInfoList() : userPcskillInfoList);
    this.skillStarInfoList = (skillStarInfoList === undefined ? new SkillStarInfoList() : skillStarInfoList);
  }

  public static copy(origin:PcskillInfo, userSkillList:UserPcskillInfoList):PcskillInfo {
    return new PcskillInfo(
      origin.userId,
      origin.pcskillId,
      origin.pcskillName,
      userSkillList,
      origin.skillStarInfoList
    );
  }
  public static create() {
    const result: PcskillInfo[] = [];
    return result;
  }
}
