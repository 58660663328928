import SkillStarInfo from './SkillStarInfo';

export default class SkillStarInfoList {
  skillStarInfoList:SkillStarInfo[];

  constructor(
    skillStarInfoList?:SkillStarInfo[],
  ) {
    this.skillStarInfoList = (skillStarInfoList === undefined ? SkillStarInfo.create() : skillStarInfoList);
  }
  public static cloneList(source:SkillStarInfoList[]):SkillStarInfoList[] {
    const result:SkillStarInfoList[] = [];
    source.forEach((s) => {
      result.push(SkillStarInfoList.clone(s));
    });
    return result;
  }
  public static clone(source:SkillStarInfoList):SkillStarInfoList {
    const result = new SkillStarInfoList();
    result.skillStarInfoList = source.skillStarInfoList;
    return result;
  }
}
