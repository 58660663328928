import MinSkillCount from './MinSkillCount';
import Star from './Star';

export default class SkillStarInfo {
  star: Star;

  minSkillCount: MinSkillCount;

  constructor(
    star?: Star,

    minSkillCount?: MinSkillCount
  ) {
    this.star = (star === undefined ? new Star() : star);
    this.minSkillCount = (minSkillCount === undefined ? new MinSkillCount() : minSkillCount);
  }

  public static cloneList(source:SkillStarInfo[]):SkillStarInfo[] {
    const result : SkillStarInfo[] = [];
    source.forEach((s) => {
      result.push(SkillStarInfo.clone(s));
    });
    return result;
  }
  public static clone(source: SkillStarInfo): SkillStarInfo {
    const result = new SkillStarInfo();
    result.star = source.star;
    result.minSkillCount = source.minSkillCount;
    return result;
  }
  public static create() {
    const result: SkillStarInfo[] = [];
    return result;
  }
}
