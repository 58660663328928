import UserPcskillInfo from './UserPcskillInfo';

export default class UserPcskillInfoList {
  value:UserPcskillInfo[];

  constructor(
    value?:UserPcskillInfo[],
  ) {
    this.value = (value === undefined ? UserPcskillInfo.create() : value);
  }

  public static cloneList(source:UserPcskillInfoList[]):UserPcskillInfoList[] {
    const result:UserPcskillInfoList[] = [];
    source.forEach((s) => {
      result.push(UserPcskillInfoList.clone(s));
    });
    return result;
  }

  public static clone(source:UserPcskillInfoList):UserPcskillInfoList {
    const result = new UserPcskillInfoList();
    result.value = source.value;
    return result;
  }
}
